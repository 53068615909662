import React from 'react';
import './image-slider.scss';
import { Grid, Card } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';

export default function ImageSlider(props) {
    const breakPoints = [
        { width: 1, itemsToShow: 2 ,itemPadding:[0, 10],showArrows: props.ImageArray.length > 2, pagination: props.ImageArray.length > 2},
        { width: 200, itemsToShow: 3 ,itemPadding:[0, 10], showArrows: props.ImageArray.length > 3, pagination: props.ImageArray.length > 3},
        { width: 904, itemsToShow: 5, itemPadding:[0, 10],showArrows: props.ImageArray.length > 5, pagination: props.ImageArray.length > 5 },
        { width: 1239, itemsToShow: 5, showArrows: props.ImageArray.length > 5, pagination: props.ImageArray.length > 5 }
      ];
   
      const items=props.ImageArray
    return (
        <Grid item xs={12} className={"image_slider "+props.class}>
          <Carousel itemPadding={[0, 20]} breakPoints={breakPoints}>
            {
              items.map((item, i) => <Item key={i} item={item}/>)
            }
         </Carousel>
        </Grid>
    )
}


function Item(props) {
    return (
      <>
        <Card className="root" >
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="" direction="column" >
              <div className="Ourslide_img">
                <img src={props.item} alt="slider Img"/>
              </div>
          </Grid>
        </Card>
      </>
    )
  }
